<template>
    <b-card no-body class="mb-2">
        <b-card-header class="align-items-center">
            <h4 class="mr-auto mb-0">
                <unicon name="user" width="18" height="18"></unicon>معلومات
                الحزمة
            </h4>
        </b-card-header>
        <ValidationObserver ref="observer">
            <b-card-body class="pt-0">
                <b-card-text>
                    <b-row>
                        <b-col cols="12" md="4">
                            <EKInputText
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الحزمة إجباري'
                                    }
                                ]"
                                v-model="packageDto.name"
                                label="اسم الحزمة"
                                placeholder="ادخل اسم الحزمة"
                                name="name"
                            />
                        </b-col>
                        <!-- <b-col cols="12" md="4" class="d-flex align-items-center">
              <b-checkbox switch ></b-checkbox>
              <label class="mr-auto mb-0">الحزمة عرض خاص</label>
            </b-col> -->
                        <b-col
                            cols="12"
                            md="4"
                            class="d-flex align-items-center"
                        >
                            <b-checkbox
                                v-model="packageDto.isHidden"
                                switch
                            ></b-checkbox>
                            <label class="mb-0 mr-auto">مخفي</label>
                        </b-col>
                        <b-col cols="12">
                            <EKInputPicker
                                label="تاريخ الإنتهاء"
                                v-model="packageDto.endDate"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'تاريخ الإنتهاء مطلوب'
                                    }
                                ]"
                                name="endDate"
                                placeholder="اختر تاريخ الإنتهاء"
                            />
                        </b-col>
                        <b-col cols="12">
                            <EKInputSelect
                                label="نوع الحزمة "
                                v-model="packageDto.type"
                                :options="typeDto"
                                name="type"
                                :placeholder="
                                    packageDto.type
                                        ? 'اختر نوع الحزمة'
                                        : 'الحزمة الثابتة'
                                "
                            />
                        </b-col>
                        <b-col cols="12" class="my-1">
                            <div style="display: flex; flex-direction: column">
                                <label>وصف الحزمة</label>
                                <b-textarea
                                    v-model="packageDto.description"
                                ></b-textarea>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card-body>
        </ValidationObserver>
        <b-card-header class="flex-column pt-0">
            <h4 class="mr-auto mb-0">
                <unicon name="user" width="18" height="18"></unicon>محتويات
                الحزمة
            </h4>
            <strong v-if="packageDto.type == 0">فلترة حسب</strong>
        </b-card-header>
        <b-card-body v-if="packageDto.type == 0">
            <ValidationObserver ref="filterTabsFaculity">
                <b-row>
                    <b-col cols="12" md="4">
                        <EKInputSelect
                            label="السنة"
                            placeholder="اختر السنة"
                            :options="[{ id: 0, name: 'الكل' }, ...subjectYear]"
                            name="year"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'السنة مطلوبة'
                                }
                            ]"
                            v-model="filterDto.year"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="4">
                        <EKInputSelect
                            label="الفصل"
                            placeholder="اختر الفصل "
                            v-model="filterDto.semesterId"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'الفصل مطلوب'
                                }
                            ]"
                            :options="[{ id: 0, name: 'الكل' }, ...semester]"
                            name="semester"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="4">
                        <EKInputSelect
                            label="الكلية"
                            placeholder="اختر الكلية"
                            v-model="filterDto.facultyId"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'الكلية مطلوب'
                                }
                            ]"
                            :options="[{ id: 0, name: 'الكل' }, ...faculties]"
                            name="faculity"
                            :clearable="true"
                        />
                    </b-col>
                </b-row>
            </ValidationObserver>
        </b-card-body>
        <b-card-body class="py-0" v-if="packageDto.type == 0">
            <EKTableCollapse
                label="label"
                :rows="subjectFaculitiesList"
                :columns="columns"
                no_delete
                childId="id"
                :innerValue="packageDto.selectedSubjectFaculties"
                childrenLabel="subjectFaculties"
                customHeaderLabel="label"
                :colapseHeader="subColumns"
                @changeSelectChildren="changeSelectChildren"
            >
                <template slot="item-td.price" slot-scope="{ tr }">
                    <td>
                        <EKInputText
                            @change="calcPackagePrice()"
                            style="width: 120px"
                            class="d-block m-auto"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'السعر إجباري'
                                },
                                {
                                    type: 'min_value:0',
                                    message: 'السعر مطلوب'
                                }
                            ]"
                            type="number"
                            placeholder="ادخل السعر"
                            name="name"
                            v-model="tr.price"
                        />
                    </td>
                </template>
                <template slot="table-footer">
                    <b-card-footer class="px-1 pt-1 pb-0 border-0 text-right">
                        <strong>
                            سعر الحزمة الإجمالي :
                            {{ packageDto.price }}
                        </strong>
                    </b-card-footer>
                </template>
            </EKTableCollapse>
            <b-col cols="12" class="d-flex justify-content-center mb-3">
                <EKPagination
                    :items="subjectsList"
                    v-model="subjectFaculitiesList"
                />
            </b-col>
            <h6
                class="text-danger text-center mb-2"
                v-if="!packageDto.selectedSubjectFaculties.length"
            >
                يجب اختيار عنصر واحد على الاقل
            </h6>
        </b-card-body>
        <b-card-body v-if="packageDto.type == 3">
            <b-row>
                <b-col cols="12" lg="6">
                    <EKInputText
                        label="عدد المواد"
                        :rules="[
                            {
                                type: 'required',
                                message: 'عدد المواد إجباري'
                            }
                        ]"
                        type="number"
                        placeholder="ادخل عدد المواد"
                        name="packageLimit"
                        v-model="packageDto.packageLimit"
                    />
                </b-col>
                <b-col cols="12" lg="6">
                    <EKInputText
                        label="السعر"
                        :rules="[
                            {
                                type: 'required',
                                message: 'السعر إجباري'
                            }
                        ]"
                        type="number"
                        placeholder="ادخل السعر"
                        name="price"
                        v-model="packageDto.price"
                    />
                </b-col>
            </b-row>
        </b-card-body>
        <b-card-footer class="py-1">
            <b-button variant="primary" @click="onSubmit()">
                {{ this.id != (0 & "") ? "تعديل" : "إضافة" }}
            </b-button>
            <b-button to="/packages" class="ml-1">تراجع</b-button>
            <b-button
                variant="danger"
                @click="deletePackage(id)"
                v-if="id"
                class="mx-1"
                >حذف الحزمة</b-button
            >
        </b-card-footer>
    </b-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKInputPicker from "@Ekcore/components/EK-forms/EK-input-picker";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapState, mapGetters, mapActions } from "vuex";
import EKTableCollapse from "@Ekcore/components/EK-table-collapse";
import EKPagination from "@Ekcore/components/EK-pagination";

export default {
    components: {
        ValidationObserver,
        EKInputSelect,
        EKInputPicker,
        EKInputText,
        EKTableCollapse,
        EKPagination
    },
    props: {
        id: String
    },
    data: () => ({
        subjectFaculitiesList: [],
        columns: [
            {
                label: "اسم المادة",
                value: "name"
            },
            {
                label: "وصف المادة",
                value: "description"
            }
        ],
        subColumns: [
            {
                label: "اسم الكلية",
                value: "facultyName"
            },
            {
                label: "سعر المادة",
                value: "price"
            }
        ]
    }),
    computed: {
        ...mapState({
            packageDto: state => state.packages.packageDto,
            typeDto: state => state.globalStore.typeDto,
            faculties: state => state.faculties.faculties,
            subjectYear: state => state.globalStore.subjectYear,
            filterDto: state => state.filter.filterDto
        }),
        ...mapGetters(["semester", "subjectsList"])
    },
    mounted() {
        this.getFacultiesDetails();
        this.fetchTotalTag();
        this.fetchSubject({ semesterId: "", year: "", facultyId: "" });
        if (this.id != (0 | "")) this.packageDetails(this.id);
    },
    methods: {
        ...mapActions([
            "fetchSubject",
            "getFacultiesDetails",
            "fetchTotalTag",
            "addPackage",
            "packageDetails",
            "setPackage",
            "deletePackage"
        ]),
        calcPackagePrice() {
            this.packageDto.price = 0;
            this.subjectsList.forEach(sub => {
                sub.subjectFaculties.forEach(subFac => {
                    if (
                        this.packageDto.selectedSubjectFaculties.indexOf(
                            subFac.id
                        ) != -1
                    ) {
                        this.packageDto.price += subFac.price;
                        return this.packageDto.price;
                    }
                });
            });
        },
        onSubmit() {
            this.$refs.observer.validate().then(async suc => {
                if (suc && this.packageDto.selectedSubjectFaculties.length) {
                    let subjectFaculties = [];
                    this.packageDto.selectedSubjectFaculties.forEach(
                        (sf, i, l) => {
                            let price;
                            this.subjectsList.forEach(s => {
                                let item = s.subjectFaculties.find(
                                    f => f.id == sf
                                );
                                if (item) price = item.price;
                            }),
                                subjectFaculties.push({
                                    price,
                                    subjectFacultyId: sf
                                });
                            if (l.length - 1 == i) {
                                if (this.id != (0 & "")) {
                                    this.setPackage({
                                        id: this.packageDto.id,
                                        name: this.packageDto.name,
                                        description: this.packageDto
                                            .description,
                                        price: this.packageDto.price,
                                        startDate: new Date(
                                            this.packageDto.startDate
                                        ),
                                        endDate: new Date(
                                            this.packageDto.endDate
                                        ),
                                        type: +this.packageDto.type,
                                        isHidden: this.packageDto.isHidden,
                                        subjectFaculties
                                    });
                                } else {
                                    this.addPackage({
                                        name: this.packageDto.name,
                                        description: this.packageDto
                                            .description,
                                        price: this.packageDto.price,
                                        startDate: new Date(
                                            this.packageDto.startDate
                                        ),
                                        endDate: new Date(
                                            this.packageDto.endDate
                                        ),
                                        type: +this.packageDto.type,
                                        isHidden: this.packageDto.isHidden,
                                        subjectFaculties
                                    });
                                }
                            }
                        }
                    );
                } else if (this.id != (0 & "")) {
                    this.setPackage({
                        id: this.packageDto.id,
                        name: this.packageDto.name,
                        description: this.packageDto.description,
                        price: this.packageDto.price,
                        startDate: new Date(this.packageDto.startDate),
                        endDate: new Date(this.packageDto.endDate),
                        type: +this.packageDto.type,
                        isHidden: this.packageDto.isHidden,
                        packageLimit: this.packageDto.packageLimit
                    });
                } else {
                    this.addPackage({
                        name: this.packageDto.name,
                        description: this.packageDto.description,
                        price: this.packageDto.price,
                        startDate: new Date(this.packageDto.startDate),
                        endDate: new Date(this.packageDto.endDate),
                        type: +this.packageDto.type,
                        isHidden: this.packageDto.isHidden,
                        packageLimit: this.packageDto.packageLimit
                    });
                }
            });
        },
        changeSelectChildren(props) {
            console.log(props);
            this.packageDto.selectedSubjectFaculties = [...props];
            this.calcPackagePrice();
        }
    },
    beforeDestroy() {
        this.$store.commit("Reset_Package_Dto");
    }
};
</script>
