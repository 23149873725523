var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_c('unicon',{attrs:{"name":"user","width":"18","height":"18"}}),_vm._v("معلومات الحزمة ")],1)]),_c('ValidationObserver',{ref:"observer"},[_c('b-card-body',{staticClass:"pt-0"},[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"rules":[
                                {
                                    type: 'required',
                                    message: 'اسم الحزمة إجباري'
                                }
                            ],"label":"اسم الحزمة","placeholder":"ادخل اسم الحزمة","name":"name"},model:{value:(_vm.packageDto.name),callback:function ($$v) {_vm.$set(_vm.packageDto, "name", $$v)},expression:"packageDto.name"}})],1),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"4"}},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.packageDto.isHidden),callback:function ($$v) {_vm.$set(_vm.packageDto, "isHidden", $$v)},expression:"packageDto.isHidden"}}),_c('label',{staticClass:"mb-0 mr-auto"},[_vm._v("مخفي")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EKInputPicker',{attrs:{"label":"تاريخ الإنتهاء","rules":[
                                {
                                    type: 'required',
                                    message: 'تاريخ الإنتهاء مطلوب'
                                }
                            ],"name":"endDate","placeholder":"اختر تاريخ الإنتهاء"},model:{value:(_vm.packageDto.endDate),callback:function ($$v) {_vm.$set(_vm.packageDto, "endDate", $$v)},expression:"packageDto.endDate"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EKInputSelect',{attrs:{"label":"نوع الحزمة ","options":_vm.typeDto,"name":"type","placeholder":_vm.packageDto.type
                                    ? 'اختر نوع الحزمة'
                                    : 'الحزمة الثابتة'},model:{value:(_vm.packageDto.type),callback:function ($$v) {_vm.$set(_vm.packageDto, "type", $$v)},expression:"packageDto.type"}})],1),_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('label',[_vm._v("وصف الحزمة")]),_c('b-textarea',{model:{value:(_vm.packageDto.description),callback:function ($$v) {_vm.$set(_vm.packageDto, "description", $$v)},expression:"packageDto.description"}})],1)])],1)],1)],1)],1),_c('b-card-header',{staticClass:"flex-column pt-0"},[_c('h4',{staticClass:"mr-auto mb-0"},[_c('unicon',{attrs:{"name":"user","width":"18","height":"18"}}),_vm._v("محتويات الحزمة ")],1),(_vm.packageDto.type == 0)?_c('strong',[_vm._v("فلترة حسب")]):_vm._e()]),(_vm.packageDto.type == 0)?_c('b-card-body',[_c('ValidationObserver',{ref:"filterTabsFaculity"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputSelect',{attrs:{"label":"السنة","placeholder":"اختر السنة","options":[{ id: 0, name: 'الكل' } ].concat( _vm.subjectYear),"name":"year","rules":[
                            {
                                type: 'required',
                                message: 'السنة مطلوبة'
                            }
                        ],"clearable":true},model:{value:(_vm.filterDto.year),callback:function ($$v) {_vm.$set(_vm.filterDto, "year", $$v)},expression:"filterDto.year"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputSelect',{attrs:{"label":"الفصل","placeholder":"اختر الفصل ","rules":[
                            {
                                type: 'required',
                                message: 'الفصل مطلوب'
                            }
                        ],"options":[{ id: 0, name: 'الكل' } ].concat( _vm.semester),"name":"semester","clearable":true},model:{value:(_vm.filterDto.semesterId),callback:function ($$v) {_vm.$set(_vm.filterDto, "semesterId", $$v)},expression:"filterDto.semesterId"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputSelect',{attrs:{"label":"الكلية","placeholder":"اختر الكلية","rules":[
                            {
                                type: 'required',
                                message: 'الكلية مطلوب'
                            }
                        ],"options":[{ id: 0, name: 'الكل' } ].concat( _vm.faculties),"name":"faculity","clearable":true},model:{value:(_vm.filterDto.facultyId),callback:function ($$v) {_vm.$set(_vm.filterDto, "facultyId", $$v)},expression:"filterDto.facultyId"}})],1)],1)],1)],1):_vm._e(),(_vm.packageDto.type == 0)?_c('b-card-body',{staticClass:"py-0"},[_c('EKTableCollapse',{attrs:{"label":"label","rows":_vm.subjectFaculitiesList,"columns":_vm.columns,"no_delete":"","childId":"id","innerValue":_vm.packageDto.selectedSubjectFaculties,"childrenLabel":"subjectFaculties","customHeaderLabel":"label","colapseHeader":_vm.subColumns},on:{"changeSelectChildren":_vm.changeSelectChildren},scopedSlots:_vm._u([{key:"item-td.price",fn:function(ref){
                        var tr = ref.tr;
return [_c('td',[_c('EKInputText',{staticClass:"d-block m-auto",staticStyle:{"width":"120px"},attrs:{"rules":[
                            {
                                type: 'required',
                                message: 'السعر إجباري'
                            },
                            {
                                type: 'min_value:0',
                                message: 'السعر مطلوب'
                            }
                        ],"type":"number","placeholder":"ادخل السعر","name":"name"},on:{"change":function($event){return _vm.calcPackagePrice()}},model:{value:(tr.price),callback:function ($$v) {_vm.$set(tr, "price", $$v)},expression:"tr.price"}})],1)]}}],null,false,3248764088)},[_c('template',{slot:"table-footer"},[_c('b-card-footer',{staticClass:"px-1 pt-1 pb-0 border-0 text-right"},[_c('strong',[_vm._v(" سعر الحزمة الإجمالي : "+_vm._s(_vm.packageDto.price)+" ")])])],1)],2),_c('b-col',{staticClass:"d-flex justify-content-center mb-3",attrs:{"cols":"12"}},[_c('EKPagination',{attrs:{"items":_vm.subjectsList},model:{value:(_vm.subjectFaculitiesList),callback:function ($$v) {_vm.subjectFaculitiesList=$$v},expression:"subjectFaculitiesList"}})],1),(!_vm.packageDto.selectedSubjectFaculties.length)?_c('h6',{staticClass:"text-danger text-center mb-2"},[_vm._v(" يجب اختيار عنصر واحد على الاقل ")]):_vm._e()],1):_vm._e(),(_vm.packageDto.type == 3)?_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('EKInputText',{attrs:{"label":"عدد المواد","rules":[
                        {
                            type: 'required',
                            message: 'عدد المواد إجباري'
                        }
                    ],"type":"number","placeholder":"ادخل عدد المواد","name":"packageLimit"},model:{value:(_vm.packageDto.packageLimit),callback:function ($$v) {_vm.$set(_vm.packageDto, "packageLimit", $$v)},expression:"packageDto.packageLimit"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('EKInputText',{attrs:{"label":"السعر","rules":[
                        {
                            type: 'required',
                            message: 'السعر إجباري'
                        }
                    ],"type":"number","placeholder":"ادخل السعر","name":"price"},model:{value:(_vm.packageDto.price),callback:function ($$v) {_vm.$set(_vm.packageDto, "price", $$v)},expression:"packageDto.price"}})],1)],1)],1):_vm._e(),_c('b-card-footer',{staticClass:"py-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" "+_vm._s(this.id != (0 & "") ? "تعديل" : "إضافة")+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"to":"/packages"}},[_vm._v("تراجع")]),(_vm.id)?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deletePackage(_vm.id)}}},[_vm._v("حذف الحزمة")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }